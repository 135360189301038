/* NORMAL */

@font-face {
  font-family: Theinhardt-Pan;
  src: url(./Theinhardt-Pan-Hairline-Trial.woff) format("woff"),
    url(./Theinhardt-Pan-Hairline-Trial.woff2) format("woff2"),
    url(./Theinhardt-Pan-Hairline-Trial.otf) format("otf");
  font-weight: 100;
}

@font-face {
  font-family: Theinhardt-Pan;
  src: url(./Theinhardt-Pan-Ultralight-Trial.woff) format("woff"),
    url(./Theinhardt-Pan-Ultralight-Trial.woff2) format("woff2"),
    url(./Theinhardt-Pan-Ultralight-Trial.otf) format("otf");
  font-weight: 200;
}

@font-face {
  font-family: Theinhardt-Pan;
  src: url(./Theinhardt-Pan-Thin-Trial.woff) format("woff"),
    url(./Theinhardt-Pan-Thin-Trial.woff2) format("woff2"),
    url(./Theinhardt-Pan-Thin-Trial.otf) format("otf");
  font-weight: 300;
}

@font-face {
  font-family: Theinhardt-Pan;
  src: url(./Theinhardt-Pan-Light-Trial.woff) format("woff"),
    url(./Theinhardt-Pan-Light-Trial.woff2) format("woff2"),
    url(./Theinhardt-Pan-Light-Trial.otf) format("otf");
  font-weight: 400;
}

@font-face {
  font-family: Theinhardt-Pan;
  src: url(./Theinhardt-Pan-Regular-Trial.woff) format("woff"),
    url(./Theinhardt-Pan-Regular-Trial.woff2) format("woff2"),
    url(./Theinhardt-Pan-Regular-Trial.otf) format("otf");
  font-weight: 500;
}

@font-face {
  font-family: Theinhardt-Pan;
  src: url(./Theinhardt-Pan-Medium-Trial.woff) format("woff"),
    url(./Theinhardt-Pan-Medium-Trial.woff2) format("woff2"),
    url(./Theinhardt-Pan-Medium-Trial.otf) format("otf");
  font-weight: 600;
}

@font-face {
  font-family: Theinhardt-Pan;
  src: url(./Theinhardt-Pan-Bold-Trial.woff) format("woff"),
    url(./Theinhardt-Pan-Bold-Trial.woff2) format("woff2"),
    url(./Theinhardt-Pan-Bold-Trial.otf) format("otf");
  font-weight: 700;
}

@font-face {
  font-family: Theinhardt-Pan;
  src: url(./Theinhardt-Pan-Heavy-Trial.woff) format("woff"),
    url(./Theinhardt-Pan-Heavy-Trial.woff2) format("woff2"),
    url(./Theinhardt-Pan-Heavy-Trial.otf) format("otf");
  font-weight: 800;
}

@font-face {
  font-family: Theinhardt-Pan;
  src: url(./Theinhardt-Pan-Black-Trial.woff) format("woff"),
    url(./Theinhardt-Pan-Black-Trial.woff2) format("woff2"),
    url(./Theinhardt-Pan-Black-Trial.otf) format("otf");
  font-weight: 900;
}

