@font-face {
  font-family: Theinhardt-Pan;
  src: url("Theinhardt-Pan-Hairline-Trial.54fcb3c9.woff") format("woff"), url("Theinhardt-Pan-Hairline-Trial.3f2d77f7.woff2") format("woff2"), url("Theinhardt-Pan-Hairline-Trial.3e282130.otf") format("otf");
  font-weight: 100;
}

@font-face {
  font-family: Theinhardt-Pan;
  src: url("Theinhardt-Pan-Ultralight-Trial.faecee21.woff") format("woff"), url("Theinhardt-Pan-Ultralight-Trial.05e04b70.woff2") format("woff2"), url("Theinhardt-Pan-Ultralight-Trial.ad85edff.otf") format("otf");
  font-weight: 200;
}

@font-face {
  font-family: Theinhardt-Pan;
  src: url("Theinhardt-Pan-Thin-Trial.aca1fc99.woff") format("woff"), url("Theinhardt-Pan-Thin-Trial.d34fe09d.woff2") format("woff2"), url("Theinhardt-Pan-Thin-Trial.bbcf3957.otf") format("otf");
  font-weight: 300;
}

@font-face {
  font-family: Theinhardt-Pan;
  src: url("Theinhardt-Pan-Light-Trial.c1ec4042.woff") format("woff"), url("Theinhardt-Pan-Light-Trial.a9b0f168.woff2") format("woff2"), url("Theinhardt-Pan-Light-Trial.5970240d.otf") format("otf");
  font-weight: 400;
}

@font-face {
  font-family: Theinhardt-Pan;
  src: url("Theinhardt-Pan-Regular-Trial.7ae9b4c9.woff") format("woff"), url("Theinhardt-Pan-Regular-Trial.a4a029aa.woff2") format("woff2"), url("Theinhardt-Pan-Regular-Trial.a67b392d.otf") format("otf");
  font-weight: 500;
}

@font-face {
  font-family: Theinhardt-Pan;
  src: url("Theinhardt-Pan-Medium-Trial.29831849.woff") format("woff"), url("Theinhardt-Pan-Medium-Trial.d7b74060.woff2") format("woff2"), url("Theinhardt-Pan-Medium-Trial.a8e49293.otf") format("otf");
  font-weight: 600;
}

@font-face {
  font-family: Theinhardt-Pan;
  src: url("Theinhardt-Pan-Bold-Trial.8f43c73c.woff") format("woff"), url("Theinhardt-Pan-Bold-Trial.f9f21926.woff2") format("woff2"), url("Theinhardt-Pan-Bold-Trial.e8a731d3.otf") format("otf");
  font-weight: 700;
}

@font-face {
  font-family: Theinhardt-Pan;
  src: url("Theinhardt-Pan-Heavy-Trial.5eb059d5.woff") format("woff"), url("Theinhardt-Pan-Heavy-Trial.117c31a2.woff2") format("woff2"), url("Theinhardt-Pan-Heavy-Trial.59fe3b39.otf") format("otf");
  font-weight: 800;
}

@font-face {
  font-family: Theinhardt-Pan;
  src: url("Theinhardt-Pan-Black-Trial.08c36b87.woff") format("woff"), url("Theinhardt-Pan-Black-Trial.cb7c9eef.woff2") format("woff2"), url("Theinhardt-Pan-Black-Trial.174e65e2.otf") format("otf");
  font-weight: 900;
}

/*# sourceMappingURL=index.1471a877.css.map */
